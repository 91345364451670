@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --background: 0 0% 0%;
  --foreground: 0 0% 100%;
  --primary: 0 100% 50%;
  --primary-foreground: 0 0% 100%;
  --secondary: 0 0% 0%;
  --secondary-foreground: 0 0% 100%;
  --accent: 0 100% 50%;
  --accent-foreground: 0 0% 100%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 0 0% 100%;
  --muted: 0 0% 20%;
  --muted-foreground: 0 0% 80%;
  --card: 0 0% 10%;
  --card-foreground: 0 0% 100%;
  --popover: 0 0% 15%;
  --popover-foreground: 0 0% 100%;
  --border: 0 0% 20%;
  --input: 0 0% 20%;
  --ring: 0 100% 50%;
  --radius: 0.5rem;
}

body {
  font-family: 'Helvetica', sans-serif;
  background-color: black;
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica', sans-serif;
}

.font-mono {
  font-family: 'Courier New', Courier, monospace;
}